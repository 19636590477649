@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600&display=swap');

html {
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  scroll-snap-type: y proximity;
}

/*16px*/
.amper {
  padding: none !important;
  line-height: 0.5 !important;
  font-size: calc(1.2vw + 2rem) !important;
  color: #b499ff !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: #000000;
}

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5,
.titulo {
  background: -webkit-linear-gradient(#b88ef0, #5e08c0);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 3rem 0 1.38rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.3;
}

h1 {
  margin: 0;
  font-size: calc(1rem + 2.6vw);
}

h2,
.h2 {
  background: #cbbcf5;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white !important;
  font-size: 2.5vh;
  font-weight: 600;
  @include phone {
    font-size: 1.4rem !important;
  }
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: calc(1rem + 1.8vw);
}

h5 {
  color: white;
  text-align: center;
  font-size: calc(1rem + 2.6vw);
}

small,
.text_small {
  font-size: 0.8rem;
  text-align: center !important;
}

.titulo {
  align-self: start;
  text-align: left !important;
  text-transform: uppercase;
  color: #b499ff;
  font-size: calc(0.2rem + 1.2vw);
  font-weight: bold;
  margin-bottom: 1rem;
  @include tablet {
    font-size: 1.5rem;
  }
}

.slick-dots li button:before {
  color: #5e08c0 !important;
}
